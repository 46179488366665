<template id="">
<div>
  <div class="regist">
    <h3><img class="logo" src="@/img/logo.png" alt="インテリジェントヘルスケア ストレスチェックApp"></h3>
    <h3>エラーが発生しました</h3>
    <p>
      大変申し訳ございませんが、再度ログインからやり直してください。<br>
      再度エラーになったり、操作の手順がわからなければ、お気軽にお問い合わせください。
    </p>
    <button class="uk-button uk-button-secondary uk-width-1-1" type="button" @click="$router.push('/client-login')" style="width: 15em;">ログインページに戻る</button>
  </div>
</div>
</template>
<script type="text/javascript">
  document.title = "エラー"
</script>
